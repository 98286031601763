import React from 'react'
import Layout from 'src/components/Layout/Layout'

const NotFoundPage = ({ pageContext }) => {
  return (
    <Layout>
      <div>404</div>
    </Layout>
  )
}

export default NotFoundPage
